/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserConfiguration } from '@/auth/utils';
import incomeExpenseStoreModule from '../incomeExpenseStoreModule';

export default function useInvoicePreviewModal(props) {
  const STORE_MODULE_NAME = 'incomeExpense';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpenseStoreModule);
  }
  const previewUrl = ref(null);
  const draftInvoice = ref(null)
  const viewDraftInvoice = ref(false)
  const toastification = toast();

  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
    draftInvoice.value = null
    viewDraftInvoice.value = false
  };



  //
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const fetchInvoiceHtml = () => {
    isLoading.value = true;
    previewUrl.value = `${process.env.VUE_APP_URL}/preview-income-expense/${itemLocal.value.id}/${itemLocal.value.uuid}`;
    store
      .dispatch('incomeExpense/previewIncomeExpense', itemLocal.value.id)
      .then(response => {
        pdfUrl.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const showModal = () => {
    resetModal();
    fetchInvoiceHtml();
  };

  const downloadInvoicePDF = () => {
    store
      .dispatch('incomeExpense/getInvoicePdf', itemLocal.value.id)
      .then(response => {
        window.location.href = response.data;

      })
      .catch(error => {
        toastification.showToastError(error);
      });


  };

  const downloadInvoiceImage = () => {
    store
      .dispatch('incomeExpense/getInvoiceImage', itemLocal.value.id)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const getViettelInvoiceDraft = () => {
    if (viewDraftInvoice.value === false && !draftInvoice.value) {
      isLoading.value = true
      store
        .dispatch('invoice/getViettelInvoiceDraft', itemLocal.value.id)
        .then(response => {
          draftInvoice.value = response.data
          viewDraftInvoice.value = true
          isLoading.value = false
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false
        });
    } else {
      viewDraftInvoice.value = false
    }

  };


  const issueViettelInvoice = () => {
    isLoading.value = true
    store
      .dispatch('invoice/issueViettelInvoice', itemLocal.value.id)
      .then(response => {
        draftInvoice.value = response.data
        viewDraftInvoice.value = true
        isLoading.value = false
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false
      });

  };


  const viewInvoiceTingeEdu = () => {
    viewDraftInvoice.value = false

  };

  const sendInvoiceViaZaloZNS = () => {
    store
      .dispatch('invoice/sendInvoiceViaZaloZNS', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Vui lòng kiểm tra kết quả gửi Zalo thông báo tại mục \"Gửi thông báo\"");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaZaloNormal = () => {
    store
      .dispatch('invoice/sendInvoiceViaZaloNormal', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Hệ thống sẽ tiến hành gửi thông báo theo số điện thoại. LƯU Ý: hóa đơn được duyệt mới có thể gửi");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaZaloOA = () => {
    store
      .dispatch('invoice/sendInvoiceViaZaloOA', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Hệ thống sẽ tiến hành gửi thông báo đến phụ huynh trong trường hợp phụ huynh liên kết thông tin trong Zalo OA");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  }

  const sendInvoiceViaApp = () => {
    store
      .dispatch('invoice/sendInvoiceViaApp', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Đã gửi thông báo phí qua App");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const sendInvoiceViaEmail = () => {
    store
      .dispatch('invoice/sendInvoiceViaEmail', [itemLocal.value.id])
      .then(() => {
        toastification.showToastSuccess("Đã gửi thông báo phí qua Email");
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchInvoiceHtml,
    showModal,
    downloadInvoicePDF,
    downloadInvoiceImage,
    sendInvoiceViaZaloZNS,
    sendInvoiceViaZaloNormal,
    sendInvoiceViaEmail,
    sendInvoiceViaApp,
    getViettelInvoiceDraft,
    pdfUrl,
    isLoading,
    previewUrl,
    draftInvoice,
    viewDraftInvoice,
    viewInvoiceTingeEdu,
    issueViettelInvoice,
    sendInvoiceViaZaloOA
  };
}
