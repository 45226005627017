<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-income-expense-preview"
    ref="refModal"
    title="In biên lai"
    hide-footer
    size="lg"
    scrollable
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="showModal"
    @hidden="resetModal"
  >
    <!-- Body -->

    <!-- <div v-if="pdfUrl" class="mt-50">
      <b-dropdown left text="Gửi hóa đơn" variant="primary" class="ml-50">
        <b-dropdown-item @click="sendInvoiceViaZaloOA">
          Gửi tin nhắn Zalo OA
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaZaloNormal">
          Gửi tin nhắn Zalo BOT
        </b-dropdown-item>
        <b-dropdown-item @click="sendInvoiceViaEmail">
          Gửi email
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown left text="Tải hóa đơn" variant="info" class="ml-50">
        <b-dropdown-item @click="downloadInvoicePDF">
          Tải xuống PDF
        </b-dropdown-item>
        <b-dropdown-item @click="downloadInvoiceImage">
          Tải xuống ảnh
        </b-dropdown-item>
      </b-dropdown>
    </div> -->

    <div class="d-flex justify-content-center my-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <iframe
      v-if="pdfUrl && !isLoading"
      :src="
        viewDraftInvoice == true && draftInvoice
          ? `data:application/pdf;base64,${draftInvoice}`
          : pdfUrl
      "
      style="width: 100%; height: 80vh; border: none"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import { required } from "@validations";
import useInvoicePreviewModal from "./useIncomeExpensePreviewModal";

export default {
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
      itemLocal,
      resetItemLocal,
      resetModal,
      fetchInvoiceHtml,
      showModal,
      downloadInvoicePDF,
      downloadInvoiceImage,
      sendInvoiceViaZaloZNS,
      sendInvoiceViaZaloNormal,
      sendInvoiceViaEmail,
      sendInvoiceViaApp,
      sendInvoiceViaZaloOA,
      getViettelInvoiceDraft,
      viewInvoiceTingeEdu,
      pdfUrl,
      isLoading,
      previewUrl,
      draftInvoice,
      viewDraftInvoice,
      issueViettelInvoice,
    } = useInvoicePreviewModal(props);

    return {
      itemLocal,
      resetItemLocal,
      resetModal,
      fetchInvoiceHtml,
      showModal,
      downloadInvoicePDF,
      downloadInvoiceImage,
      sendInvoiceViaZaloZNS,
      sendInvoiceViaZaloNormal,
      sendInvoiceViaEmail,
      sendInvoiceViaApp,
      getViettelInvoiceDraft,
      viewInvoiceTingeEdu,
      issueViettelInvoice,
      sendInvoiceViaZaloOA,
      pdfUrl,
      isLoading,
      previewUrl,
      draftInvoice,
      viewDraftInvoice,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
